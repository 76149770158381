import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./productSlider.css";
import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { addToCart,addToTempCart } from "../../../utils/apiUrlsSetup"
import { toast } from "react-toastify";
import EditQuantityButton from "../../shared-components/editQuantityButton/editQuantityButton"
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const ProductSlider = (props) => {

  const { t, i18n } = useTranslation();
  const [currencyCode, setCurrencyCode] = useState('Kz')
  const authToken = localStorage.getItem("authToken") || ""
  const [cartSession, setCartSession] = useState( localStorage.getItem("cart-session") || "" )

  let navigate = useNavigate();
  const [disableQtyButton, setDisableQtyButton] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 2000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1200 },
      items: 5,
    },
    largetablet: {
      breakpoint: { max: 1200, min: 769 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const gotoProductPage = (data) => {
    const name = data.item_slug.replace(/ /g, "-");
    navigate(`/product/${name}/${data.product_id}`);
  };

  const decrement = (data) => {
    if (data.quantity > 0) {
      const copyListData = [...props.listData]

      for (let i = 0; i < copyListData.length; i++) {
      const items = copyListData[i].items
      const selectedItem = items.filter((v) => v.product_id === data.product_id)
      if(selectedItem.length){
        selectedItem[0].quantity = selectedItem[0].quantity - 1
        addProductToCart(selectedItem[0])
      }
      }
      props.setListData(copyListData)

    }
  }

  const increment = (data) => {

    if (data.quantity < data.current_stock && inputValue < data.current_stock) {
      const copyListData = [...props.listData]
      
      let repeat = false
      for (let i = 0; i < copyListData.length; i++) {
        const items = copyListData[i].items
        const selectedItem = items.filter((v) => v.product_id === data.product_id)

        if (inputValue && selectedItem.length) {
          if(repeat)
          {
            selectedItem[0].quantity = inputValue
          }
          else
          {
            repeat=true
            selectedItem[0].quantity = inputValue
            addProductToCart(selectedItem[0])
          }
        }
        else if(selectedItem.length){
          if(repeat)
          {
            selectedItem[0].quantity = selectedItem[0].quantity + 1
          }
          else
          {
            repeat=true
            selectedItem[0].quantity = selectedItem[0].quantity + 1
            addProductToCart(selectedItem[0])
          }
        }
      }

      props.setListData(copyListData)

    }
    else {
      toast.error(`{t("notthere")}`)
    }
  }

  const addBtnHandler = (index, data) => {
    

    if (authToken !== '') {
      increment(data)
    }
    else {
      // toast.error("Please Login First", { autoClose: 1200 })
      if(cartSession)
      {
        increment(data)
        // toast.error("Session Created", { autoClose: 1200 })
      }
      else
      {
        toast.error(`{t("first)}`, { autoClose: 1200 })
      }

    }
  }

  const addProductToCart = async (productData) => {
    setDisableQtyButton(true)
    const formData = new FormData()
    formData.append("offer_price", productData.discount_price)
    formData.append("rate", Number(productData.mrp))
    formData.append("quantity", productData.quantity)
    formData.append("product_id", productData.product_id)
    
    if(!authToken && cartSession)
    {
      const response = await addToTempCart(formData)
      if (response.ErrorCode === 0) {
        toast.success("Cart updated successfully.", { autoClose: 1000 })
        // props.updateListData();
        props.getCartItemsCount()
        // setDisableQtyButton(false)
      }
      else if (response && response.message === "Unauthenticated.") {
        toast.error(`{t("first")}`, { autoClose: 1200 })
      }
    }
    else
    {
      const response = await addToCart(formData)
      if (response.ErrorCode === 0) {
        // toast.success("Cart updated successfully.", { autoClose: 1000 })
        // props.updateListData();
        props.getCartItemsCount()
        // setDisableQtyButton(false)
      }
      else if (response && response.message === "Unauthenticated.") {
        toast.error(`{t("first")}`, { autoClose: 1200 })
      }
  
    }

  }
  return (
    <>
      <div className="productWrapper">
        {props?.listData ? props?.listData.map((data, index) => {
          return (
            <div className="row" key={index}>
              {data.type === "product_carousel" && data.items.length ? <>
                <h4 className="title pt-4 pb-3 font_heading">
                  <span className="carousel_heading">{data.carousel_name}</span>
                    {/* {data.carousel_name} */}
                  
                   { data?.id && <span onClick={()=>{navigate(`/carousel/${data.id}`)}} className="float-right font_basic">{t("SeeAll")}</span>}
                </h4>
              <div className="productSlider">
                <Carousel responsive={responsive} swipeable draggable>
                  {data.items.map((carouselData, index) => {
                     

                    return (
                      <>
                        {carouselData.product_image ? <div
                          className="productCard cursor-pointer relative hover:border-lime-500 mb-4 px-3"
                          key={index}
                        >

                        {carouselData.item_discount && carouselData.item_discount.split(".",1)!='0' &&

                          <div className="top_notch absolute rounded-r-lg left-0 top-4 text-xs pl-2 text-white">
                           <p className="m-0">{`${carouselData.item_discount.split(".",1)}% ${t("off")}`}</p>
                          </div>
                          
                          }

                          <Link
                              to={`/product/${carouselData.item_slug.replace(/ /g, "-")}/${carouselData.product_id}`}
                              className="text-decoration-none"
                              style={{ padding: "1px", fontSize: "0.4rem" ,margin: "0",
                              '@media only screen and (max-width: 600px)': {
                                      marginLeft: "5px", 
                                  },
                              }} 
                          >

                          <div className="product_img flex justify-center">
                            <img
                              alt="product_image"
                              src={carouselData.product_image}
                              loading="lazy"
                              className="block w-full"
                            />
                          </div>
                          <p
                            className="m-0 product_name font_basic"
                          >
                            {carouselData.product_name}
                          </p>
                          </Link>

                          
                          {carouselData?.packing_info?
                          <p>
                              {carouselData?.packing_info}
                          </p>                    
                          :null
                          }
                          {carouselData?.variants &&  carouselData?.variants.length > 0 ?
                            <div>                        
                              <b>{t("Sizes")}</b>
                              <div class="flex flex-wrap font_paragraph variants_options variant_in_slider">
                                  {carouselData?.variants.map((details, index) => (
                                    <Link
                                        to={`/product/${details.product_slug.replace(/ /g, "-")}/${details.product_id}`}
                                        className="text-decoration-none font_paragraph"
                                        style={{ padding: "0", margin: "0",
                                            '@media only screen and (max-width: 600px)': {
                                                marginLeft: "5px", 
                                            },
                                        }} 
                                    >
                                  
                                      
                                      {/* <span onClick={()=>{  navigate({ pathname: `/product/${details.product_slug}/${details.product_id}` }); }} */}
                                      <span className={carouselData?.variant_combo==details.variant_combination?"active":null}                                
                                      > {details.variant_combination}</span>
                                    </Link>
                                  ))} 
                              </div>            
                            </div>            
                            :null
                          }

                          <div className="bottom_items absolute bottom-2 left-0 w-full px-3">
                            {/* <span className="gray_text font_family_common"> */}
                              {/* {carouselData.quantity ? carouselData.quantity : 0} */}
                            {/* </span> */}

                            {/* {carouselData?.["group_price"] ?
                            <div dangerouslySetInnerHTML={{ __html: carouselData?.["group_price"] }} className="small-text text-green-700 mt-3" />
                            :null
                            } */}

                            <div className=" row card_bottom flex justify-between mt-1">
                              <div className="card_product_price w-6/12  d-flex flex-wrap">
                              <div className="prices-container">
                                <span className=" col-md-6 col-12 discount_price text-base m-0 block font_basic">
                                {currencyCode}{(Math.floor(carouselData.discount_price))}
                                </span>
                                <span className="col-md-6 col-12actual_price gray_text text-sm line-through font_family_common">&nbsp;
                                {parseFloat(carouselData.mrp) > parseFloat(carouselData.discount_price) ? 
                                <span >
                                  {currencyCode}{Math.floor(carouselData.mrp)} 
                                </span>
                                :''
                                }
                                </span>
                                </div>
                              </div>
                             

                              {carouselData.quantity > 0 ?
                              <EditQuantityButton
                                decrement={decrement}
                                increment={increment} 
                                productData={carouselData} 
                                inputValue={inputValue} 
                                setInputValue={setInputValue}
                                disableQtyButton={disableQtyButton}
                              />
                                :

                                <>
                                  {carouselData.current_stock <= 0 ?
                                    <p className="m-0 d-flex justify-content-center align-items-center font-semibold py-1 font_10 text-danger text-uppercase"> {t("")}</p>
                                    :
                                    <button
                                      className="w-8/12 h-10 text-center text-green font-semibold py-1 px-3 card_add_btn rounded-lg"
                                      onClick={() => { addBtnHandler(index, carouselData) }}
                                    >
                                    {t("ADD")}
                                       
                                    </button>}
                                </>
                              }
                            </div>
                          </div>
                        </div>
                          : 
                        null}
                      </>
                    );
                  })}
                </Carousel>
              </div>
              </> 
              
              :
              
              <div className="banner_carousel mt-4">
                  {data.type === "banner_carousel" && data.items.map((banner, index)=>{


return <div className={data.banner_items?`banner_container my-4 cursor`:`banner_container my-4`} key={banner.id} onClick={data.banner_items?  ()=>{navigate(`/carousel/${data.id}`)}:false} >
                      <img src={banner.desktop_banner} alt="banner_img" />
                    </div>
                   })}
                
              </div>
               }
            </div>
          );
        }) : null} 
      </div>
      <style jsx>
        {`
              .productSlider{
                width: 100%;
              }
              .prices-container {
                display: flex;
                justify-content: space-between;
                margin-top: 8px;
              }
              .discount_price {
                width: 48%; 
              }
              
              .actual_price {
                width: 48%; 
                text-align: right;
                color: #9ca3b0;
              }
              
          .title {
            color: rgb(28, 28, 28);
            font-size: 24px;
            font-family: Okra-Medium;
            font-weight: 600;
            line-height: 32px;
          }
          .title span {
            line-height: 32px;
            font-size: 20px;
            color: rgb(12, 131, 31);
            cursor: pointer;
          }

          .productCard {
            min-height: 380px;
            width: 230px;
            width: 23.85%;
            border: 1px solid lightgray;
            border-radius: 8px;
            padding: 12px;
          }
          .react-multi-carousel-item .productCard {
            width: 230px;
          }

          .productCard .top_notch {
            height: 32px;
            display:flex;
            align-items:center;
            background: #0e948d;;
          }

          .productCard .top_notch p {
            margin-top: 2px;
          }

          .product_img {
            width: 100%;
          }

          .product_img img {
            height: 174px;
            width: 174px;
          }

          .productCard .product_name {
            font-weight: 380;
            font-size: 15px;
            line-height: 20px;
          }

          .gray_text {
            color: #9ca3b0;
            font-weight: 300;
            font-size: 18px;
          }

          .popup-content {
            border-radius: 10px;
            width: 40%;
            }

          .productSlider .react-multi-carousel-list .react-multiple-carousel__arrow{
            z-index:998 !important;
          }

          @media screen and (max-width:600px){
              .productCard {
              min-height: 320px;
              width: 164px;
              border: 1px solid lightgray;
              border-radius: 8px;
              padding: 12px;
            }
            .react-multi-carousel-item .productCard {
              width: 164px;
            }
            .prices-container {
              display: flex;
              justify-content: space-between;
              margin-top: 8px;
            }
            .discount_price {
              width: 48%; /* Adjust as needed */
            }
            
            .original_price {
              width: 48%; /* Adjust as needed */
              text-align: right;
              color: #9ca3b0;
            }
            .gray_text{
              font-size:10px!important;
            }
            
            .productCard .product_name
            {
              font-size:10px;
            }
            .productCard .top_notch{
              height: 24px;
            }
            .productCard .top_notch p{
              font-size: 12px;
            }
            .product_img img {
              height: auto;
              width: 140px;
            }
        

          }
          @media screen and (max-width:400px){
            .productCard {
            min-height: 300px;
            width: 152px;
            }
            .react-multi-carousel-item .productCard {
              width: 152px;
            }
  
          }


`}
      </style>
    </>
  );
};

export default ProductSlider;
