import React, { useState, useRef, useEffect, useMemo } from "react"
import Header from "../shared-components/header/header"
import Footer from "../shared-components/footer/footer"
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom"

const DownloadPage = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation("");
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};

    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
    
        if (/android/i.test(userAgent)) {
            return "Android";
        }
    
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
    
        return "unknown";
      }

      const download = async () => {
        let platform = getMobileOperatingSystem()
        if(platform=='Android')
        {
          window.location.href = "http://play.google.com/store/apps/details?id=com.techstreet.angocart&hl=en_IN";
          
        }
        if(platform=='iOS')
        {
          window.location.href = "http://apps.apple.com/in/app/angocart/id1658541323";
    
        }
        else
        {
          navigate("/")
        }
        console.log("hello1");
      }
      useEffect(()=>{
        download()
    
      },[])
        
    return <>
        <Header
            userDetails={userDetails}
        />
        <div className="row m-0 mt-5 shipping_page">
            <div className="col-0 col-md-1"></div>
            <div className="col-12 col-md-10">
                <div class="">
                    <p>
                        <strong>{t("Shipping Policy")}</strong>
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>{t("shippingcharges")}</strong>
                    </p>
                    <p>
                        {t("nodel")}
                    </p>
                    <p>
                        <strong>{t("expectedtime")}</strong>
                    </p>
                    <p>
                     {t("sp1")}
                    </p>
                    <p>
                       {t("sp2")}
                    </p>
                    <p>
                        <strong> {t("sp3")}</strong>
                    </p>
                    <p>
                      {t("sp4")}
                    </p>
                    <p>
                        <strong> {t("sp5")}</strong>
                    </p>
                    <p>
                   {t("sp6")}
                    </p></div>
            </div>
            <div className="col-0 col-md-1"></div>
        </div>
        <Footer />

        <style jsx>
            {`
        
        .shipping_page{
            margin-top: 120px !important;
        }
        `}
        </style>
    </>

}

export default DownloadPage;