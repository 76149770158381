import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import productImage from "../../../assests/product_img.png"
import "./productCard.css"
import {
    addToCart,
    cartList,
    addToTempCart
} from "../../../utils/apiUrlsSetup"
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import UseOutsideClick from "../detectOutsideClick/useOutSideClick";
import { toast } from "react-toastify";
import EditQuantityButton from "../../shared-components/editQuantityButton/editQuantityButton"
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
const ProductCard = ({ catProductList, setCatProductList, brandProduct, getCartItemsCount }) => {
    const ref = useRef()
    const { t, i18n } = useTranslation();
    const [currencyCode, setCurrencyCode] = useState('Kz')
    const navigate = useNavigate()
    const authToken = localStorage.getItem("authToken") || "";
    const [cartSession, setCartSession] = useState( localStorage.getItem("cart-session") || "" )

    const [openSelect, setOpenSelect] = useState(false)
    const [inputValue, setInputValue] = useState()


    // const toggleSelectBox = () => {
    //     setOpenSelect(!openSelect)
    // }

    UseOutsideClick(ref, () => {
        if (openSelect) {
            setOpenSelect(false)
        }
    })

    const decrement = (item, index) => {
        if (item.quantity > 0) {
            const copyCatProductList = [...catProductList]

            copyCatProductList[index].quantity = Number(item.quantity) - 1;
            setCatProductList(copyCatProductList)
            if (authToken) {
                updateCartData(copyCatProductList[index])
            }
            else if (cartSession) {
                updateCartData(copyCatProductList[index])
            }
            else {
                toast.error(`{t("first")}`, { autoClose: 1200 })
            }

        }
    }

    const increment = (item, index) => {
        if (authToken && item.quantity < item.current_stock) {
            const copyCatProductList = [...catProductList]

            if (inputValue) {
                copyCatProductList[index].quantity = inputValue;
            }
            else {
                copyCatProductList[index].quantity = Number(item.quantity) + 1;
            }
            setCatProductList(copyCatProductList)

            if (authToken) {
                updateCartData(copyCatProductList[index])
            }
            else {
                toast.error(`{t("first")}`, { autoClose: 1200 })
            }
        }
        else if (cartSession && item.quantity < item.current_stock) {
            const copyCatProductList = [...catProductList]

            if (inputValue) {
                copyCatProductList[index].quantity = inputValue;
            }
            else {
                copyCatProductList[index].quantity = Number(item.quantity) + 1;
            }
            setCatProductList(copyCatProductList)

            if (cartSession) {
                updateCartData(copyCatProductList[index])
            }
            else {
                toast.error(`{t("first")}`,{ autoClose: 1200 })
            }
        }

        else if (!authToken) {
            toast.error(`{t("first")}`,{ autoClose: 1200 })
        }
    }

    const updateCartData = async (cartData) => {
        const formData = new FormData()
        formData.append("offer_price", cartData.discount_price)
        formData.append("rate", Number(cartData.mrp))
        formData.append("quantity", cartData.quantity)
        formData.append("product_id", cartData.product_id)

        if(cartSession && !authToken)
        {
            const response = await addToTempCart(formData)
            if (response.ErrorCode === 0) {
                if (getCartItemsCount) {
                    getCartItemsCount()
                }
                // toast.success(response.ErrorMessage)
            }

        }
        else
        {
            const response = await addToCart(formData)
            if (response.ErrorCode === 0) {
                if (getCartItemsCount) {
                    getCartItemsCount()
                }
                // toast.success(response.ErrorMessage)
            }
        }

    }

    const gotoProductPage = (data) => {
        /*
        document.addEventListener('keydown', function(event1){
            if(event1.ctrlKey)
            {
                const name = data.item_slug.replace(/ /g, "-");
                window.open(`/product/${name}/${data.product_id}`,'_blank');
            }
            else if(event1.type === 'click')
            {
                const name = data.item_slug.replace(/ /g, "-");
                navigate(`/product/${name}/${data.product_id}`);

            }
            else
            {
                console.log(`Key: ${event1.key} with keycode ${event1.keyCode} has been pressed`);

            }
            console.log(`Key: ${event1.key} with keycode ${event1.keyCode} has been pressed`);
        })
        */
        // const name = data.product_name.replace(/ /g, "-");
        const name = data.item_slug.replace(/ /g, "-");
        navigate(`/product/${name}/${data.product_id}`);

    };

    return <>
        {/* {!brandProduct ? <div className="category_heading d-block d-md-flex justify-content-between p-0 mb-2">
            <p className="m-0 p-0 font_basic">Buy Vegetables Online</p>
            <div className=" d-flex p-0 position-relative">
                <span className="text-secondary font_13 ms-2 ms-md-0">Sort By</span>
                <div className="sortBy_list border px-2 ms-1 font_13 font_500 text-success cursor-pointer" onClick={toggleSelectBox}>Vegetables</div>
                {openSelect ? <ul className="list-unstyled position-absolute bg-white sortBy_list_custom_select" ref={ref}>
                    {[...Array(5)].map((val, index) => {
                        return <li className="list-item d-flex py-2 border-bottom">
                            <div class="round mx-2">
                                <input type="checkbox" id="checkbox" />
                                <label for="checkbox"></label>
                            </div>
                            <a href="#" className={`item-link text-decoration-none ms-2 ${index === 1 ? "text-success" : null}`}>veggies</a>
                        </li>
                    })}
                </ul> : null}
            </div>
        </div> : null} */}
        {
            (catProductList || []).map((val, index) => {
                return <div className={`productCard cursor-pointer my-1 relative px-2 px-md-3 ${brandProduct ? "margin_end_custom mb-2 brandCard" : 'ms-md-2 catwise_product_card'}`} key={index}>
                    {val.item_discount?
                        <div className="top_notch d-flex align-items-center absolute rounded-r-lg left-0 top-4 text-xs pl-2 text-white">
                            <p className="m-0">{`${val.item_discount.split(".",1)}% ${t("off")} `}</p>
                        </div>
                        :''    
                    }

                    <Link
                        to={`/product/${val.item_slug.replace(/ /g, "-")}/${val.product_id}`}
                        className="text-decoration-none"
                        style={{ padding: "1px", fontSize: "0.4rem" ,margin: "0",
                        '@media only screen and (max-width: 600px)': {
                                marginLeft: "5px", 
                            },
                        }} 
                    >

                    {/* <div className="product_img flex justify-center" onClick={() => { gotoProductPage(val) }}> */}
                    <div className="product_img flex justify-center">
                        <img
                            src={val.product_image ? val.product_image === "0" ? productImage : val.product_image : productImage}
                            alt="product"
                            loading="lazy"
                            className="block w-full"
                            />
                    </div>
                    {/* <p className="m-0 product_name font_basic" onClick={() => { gotoProductPage(val) }}> */}
                    <p className="m-0 product_name font_basic">
                        {val.product_name}
                    </p>
                    </Link>
                    {val?.packing_info?
                    <p>
                        {val?.packing_info}
                    </p>                    
                    :null
                    }
                    {val?.variants &&  val?.variants.length > 0 ?
                    <div>                        
                        {val?.variants ?
                            <b>{t("Sizes")} - </b>
                            :''
                        }
                        <div class="flex flex-wrap font_paragraph variants_options variant_in_slider">
                            {val?.variants.map((details, index) => (
                            <Link
                                to={`/product/${details.product_slug.replace(/ /g, "-")}/${details.product_id}`}
                                className="text-decoration-none font_paragraph"
                                style={{ padding: "0", margin: "0",
                                '@media only screen and (max-width: 600px)': {
                                        marginLeft: "5px", 
                                    },
                                }} 
                            >


                                {/* <span onClick={()=>{ navigate({ pathname: `/product/${details.product_slug}/${details.product_id}` }); }} */}
                                <span className={val?.variant_combo==details.variant_combination?"active":null}                                
                                > {details.variant_combination}</span>
                            </Link>

                            ))}
                        </div>
                    </div>            
                        : null} 

                    <div className="bottom_items absolute bottom-2 left-0 w-full px-2 px-md-3">
                        {/* <span className="gray_text font_family_common">
                            {val.quantity ? val.quantity : 0}
                        </span> */}
                        {/* {val?.["group_price"] ?
                        <div dangerouslySetInnerHTML={{ __html: val?.["group_price"] }} className="small-text text-green-700 mt-3" />
                        :null
                        } */}
                          <div className="bottom_items absolute bottom-2 left-0 w-full px-2 px-md-3">
                        <div className=" row card_bottom flex justify-between mt-1">
                            <div className="  product_price w-3/4">
                            <div className="prices-container">
                                <span className="discount_price t text-base m-0 block font_basic">{currencyCode}{Math.floor(val.discount_price)}</span>
                                <span className="actual_price gray_text text-sm line-through font_family_common">&nbsp;
                                {parseFloat(val.mrp) > parseFloat(val.discount_price) ? 
                                <span>
                                  {currencyCode}{Math.floor(val.mrp)} 
                                </span>
                                :''
                                }
                                </span>
                                </div>
                                {/* <span className="actual_price gray_text text-sm line-through font_family_common">{currencyCode}{Math.floor(val.mrp)}</span> */}
                            </div>
                            </div>
                            {val.quantity > 0 ? <>
                                <button className="bg_green h-10 font-semibold py-1 px-2 px-md-6 card_add_btn rounded-lg d-flex justify-content-between align-items-center text-white">
                                    <span
                                        className="pe-1 me-1 pe-md-0 me-md-0 ps-md-1 ms-md-1"
                                        onClick={() => { decrement(val, index) }}
                                    >
                                        &minus;
                                    </span>
                                    <Popup
                                        className="search-popup"
                                        trigger={
                                            <button className="text-primary  fs-sm-8 cursor-pointer text-white text-lg ">
                                                {val ? val.quantity : 0}
                                            </button>
                                        }
                                        modal
                                    >
                                        {(close) => (
                                            <div className="modal d-flex position-relative w-100">
                                                <div className="border-b mx-2 py-1 position-absolute font-bold text-base border-black flex justify-between items-center w-100 px-3 font_basic">
                                                    {t(" EntertheQuantityhere")}{" "}
                                                    <button
                                                        className="close cursor-pointer  text-2xl"
                                                        onClick={close}
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                                <div className="content mx-2 my-2">
                                                    <div className="mt-4">
                                                        <input
                                                            min="0"
                                                            max={val.current_stock}
                                                            type="number"
                                                            className="phone font_paragraph popup_quantity_input border bg-white rounded-full h-10 mb-2 mt-4 shadow-primary placeholder-black placeholder-opacity-40 text-xs focus:outline-none p-4"
                                                            placeholder="Quantity"
                                                            value={inputValue}
                                                            onChange={(e) =>
                                                                setInputValue(Number(e?.target?.value))
                                                            }
                                                        />

                                                    </div>

                                                    <button
                                                        className=" rounded-full bg-primary text-md h-10 mt-4 mb-1 text-white w-full font_basic"
                                                        onClick={() => {
                                                            if (inputValue) {
                                                                // console.log(val)
                                                                increment(val, index);
                                                                setInputValue('')
                                                            }
                                                            close();

                                                        }

                                                        }
                                                    >
                                                        {t("Update")}
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                                    <span
                                        className="ps-1 ms-1 ps-md-0 ms-md-0 pe-md-1 me-md-1"
                                        onClick={() => { increment(val, index) }}
                                    >
                                        &#10011;
                                    </span>
                                </button></>
                                :

                                <>

                                    {val.current_stock > 0 ? <button
                                        className="bg-transparent h-10 text-center text-green font-semibold py-1 px-3 px-md-6 card_add_btn rounded-lg"
                                        onClick={() => { increment(val, index) }}
                                    >
                                         {t("ADD")}

                                    </button> : <p className="out_stock_text text-center">{t("OutofStock")}</p>}
                                </>
                            }
                        </div>
                    </div>
                </div>
            })
        }
    </>
}

export default ProductCard;